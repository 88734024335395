import { useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import ErrorBoundary from './components/ErrorBoundary';
import StartPage from './components/StartPage';
import ProductPage from './components/ProductPage';
import CheckoutPage from './components/CheckoutPage';
import OrderOverviewPage from './components/OrderOverviewPage';
import OrderSuccessPage from './components/OrderSuccessPage';
import OrderFailedPage from './components/OrderFailedPage';
import OrderPendingPage from './components/OrderPendingPage';
import OrderInfoPage from './components/OrderInfoPage';
import PageNotFound from './components/PageNotFound';
import ContentPage from './components/ContentPage';
import ShoppingCartPage from './components/ShoppingCartPage';

const App = () => {

    useLocation();

    const body = document.querySelector("body");
    var [previousUrl, setPreviousUrl] = useState(document.location.href);

    function jumpToShop() {
        var fixedHeaderHeight = global.config.backendshop || global.config.preview ? 128 : 0;
        var shopDivOffset = document.getElementById("shop").getBoundingClientRect().top + window.scrollY;
        let websiteheader = document.getElementsByTagName('header')[0];
        if (websiteheader !== null && typeof (websiteheader) !== 'undefined' && window.getComputedStyle(websiteheader).getPropertyValue('position') === 'fixed') {
            var el = document.getElementsByTagName('header')[0];
            fixedHeaderHeight = el.offsetHeight;
            fixedHeaderHeight += parseInt(window.getComputedStyle(el).getPropertyValue('margin-bottom'));
        }
        window.scrollTo({ top: shopDivOffset - fixedHeaderHeight });
    }

    function getComponentByParam() {
        const queryParameters = new URLSearchParams(window.location.search);
        const path = queryParameters.get("path");
        if (path === null || path === 'start') {
            if (path === 'start') {
                jumpToShop();
            }
            return < StartPage />
        }
        else if (path.includes('/')) {
            jumpToShop();
            return < ProductPage />
        }
        else if (path === 'kasse') {
            jumpToShop();
            return < CheckoutPage />
        }
        else if (path === 'bestelluebersicht') {
            jumpToShop();
            return < OrderOverviewPage />
        }
        else if (path === 'bestellung-abgeschlossen') {
            jumpToShop();
            return < OrderSuccessPage />
        }
        else if (path === 'bestellung-fehlgeschlagen') {
            jumpToShop();
            return < OrderFailedPage />
        }
        else if (path === 'bestellung-ausstehend') {
            jumpToShop();
            return < OrderPendingPage />
        }
        else if (path === 'bestellinformationen') {
            jumpToShop();
            return < OrderInfoPage />
        }

        else if (path === 'warenkorb') {
            jumpToShop();
            return < ShoppingCartPage />
        }

        else if (['impressum', 'agb', 'datenschutz', 'widerrufsbelehrung', 'einloesebedingungen'].includes(path)) {
            jumpToShop();
            return < ContentPage />
        }
        else {
            jumpToShop();
            return < PageNotFound />
        }
    }


    function getClassBySize(size) {

        if (size > 1679) {
            return 'xxl'
        }
        else if (size > 1399 && size < 1680) {
            return 'xl'
        }
        else if (size > 1199 && size < 1400) {
            return 'lg'
        }
        else if (size > 991 && size < 1200) {
            return 'md'
        }
        else if (size > 767 && size < 992) {
            return 'sm'
        }
        else {
            return 'xs'
        }
    }

    const ref = useRef(null);

    useEffect(() => {
        const element = ref?.current;
        if (!element) return;

        const observer = new ResizeObserver(() => {
            element.setAttribute("class", "gw_wrapper");
            let sizeClass = getClassBySize(element.offsetWidth);
            element.classList.add(sizeClass);
        });
        observer.observe(element);



        const _paq = window._paq;
        const pageobserver = new MutationObserver(() => {
            if (typeof _paq !== "undefined") {
                if (document.location.href !== previousUrl) {
                    _paq.push(['setReferrerUrl', previousUrl]);
                    _paq.push(['setCustomUrl', document.location.href]);
                    _paq.push(['trackPageView']);
                    setPreviousUrl(document.location.href);
                }
            }
        });

        pageobserver.observe(body, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
            pageobserver.disconnect();
        };
    }, [ref, body, previousUrl])

    return (
        <div className="gw_wrapper" ref={ref}>
            <ErrorBoundary>
                {getComponentByParam()}
            </ErrorBoundary>
        </div>
    );
}

export default App;
