export const setSpeech = speech => ({
  type: 'SET_SPEECH',
  speech
});

export const setUseShoppingCart = useshoppingcart => ({
  type: 'SET_USESHOPPINGCART',
  useshoppingcart
});

export const setPredefinedCode = predefinedcode => ({
  type: 'SET_PREDEFINEDCODE',
  predefinedcode
});

export const setApiError = apierror => ({
    type: 'SET_APIERROR',
    apierror
});