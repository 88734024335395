import { useState, useEffect } from 'react';
import HelperService from "../../services/helper.service";
import ProductOptionsChoose from './elements/ProductOptionsChoose';
import ProductOptionsConfigure from './elements/ProductOptionsConfigure';
import ProductOptionsAll from './elements/ProductOptionsAll';
import ProductFormOptionsChoose from './elements/ProductFormOptionsChoose';
import ProductFormOptionsAmount from './elements/ProductFormOptionsAmount';
import ProductContingent from './elements/ProductContingent';
import ProductDiscount from './elements/ProductDiscount';
import ProductCouponInfo from './elements/ProductCouponInfo';
import ProductOptionsPackaging from './elements/ProductOptionsPackaging';
import ProductFormOptionsPackaging from './elements/ProductFormOptionsPackaging';
import ProductOptionsShipping from './elements/ProductOptionsShipping';
import ProductFormOptionsShipping from './elements/ProductFormOptionsShipping';
import ProductDeliveryTime from './elements/ProductDeliveryTime';
import ProductPersonalMessage from './elements/ProductPersonalMessage';
import ProductCouponCode from './elements/ProductCouponCode';
import ProductBotButton from './elements/ProductBotButton';
import ProductPaymentButtons from './elements/ProductPaymentButtons';
import ProductFormOptionsAppointment from './elements/ProductFormOptionsAppointment';

import { useSelector, useDispatch } from 'react-redux';
import * as ProductPageStore from '../../store/productpage/actions';
import * as CartStore from '../../store/cart/actions';
import ProductBotFields from './elements/ProductBotFields';
import ProductOptionsAppointment from './elements/ProductOptionsAppointment';
import ProductTemplateButton from './elements/ProductTemplateButton';


const ProductOptions = () => {

    const dispatch = useDispatch();
    const product = useSelector(state => state.productpage.data);
    const activeAmount = useSelector(state => state.productpage.amount);
    const activeProductData = useSelector(state => state.productpage.productdata);

    const [couponActivePrice, setCouponActivePrice] = useState(null);
    const useshoppingcart = useSelector(state => state.global.useshoppingcart);

    useEffect(() => {
        if (typeof (product) !== 'undefined' && product.length !== 0 && typeof (activeProductData) === 'undefined') {
            dispatch(ProductPageStore.setProducts(product.products.products));
            dispatch(ProductPageStore.setDistributionProducts(product.products['distribution-products']));
            dispatch(ProductPageStore.setPayments(product.shop['paymentProviders']));
            dispatch(ProductPageStore.setGreetingText(product.shop.shopDetails['greeting-text-default']));

            let firstpackaging = product.products['distribution-products'][0]['shipping-packaging'];
            let firstpackagingprice = firstpackaging['price']['gross-amount']['amount'];
            let firstshipping = firstpackaging['shipping-provider-products'][0]['shipping-method-products'][0];
            let firstshippingprice = firstshipping['price']['gross-amount']['amount'];

            dispatch(ProductPageStore.setPackaging({
                'id': firstpackaging.id,
                'name': firstpackaging.description,
                'price': firstpackagingprice
            }));

            dispatch(ProductPageStore.setShipping({
                'id': firstshipping.id,
                'name': firstshipping.description,
                'price': firstshippingprice,
                'type': firstshipping.type
            }));

            let firstappointment;
            if (product.products['appointment-based']) {
                dispatch(ProductPageStore.setAppointmentProducts(product.products.appointments));
                dispatch(ProductPageStore.setAppointment(product.products.appointments[0]));
                dispatch(CartStore.setCartAppointment(product.products.appointments[0]));
                firstappointment = product.products.appointments[0];
            }

            const producttype = product.products['configuration-type'];
            let firstActiveProduct;
            let data;

            if (producttype === 'choose') {
                product.products.products.forEach(p => {
                    if (p['available-contingent'] === null || (p['available-contingent'] !== 0 && p['minimum-quantity'] < p['available-contingent'])) {
                        if (typeof (firstActiveProduct) === 'undefined') {
                            firstActiveProduct = p;
                        }
                    }
                })
                if (typeof (firstActiveProduct) === 'undefined') {
                    firstActiveProduct = product.products.products[0]
                }
                data = {
                    'id': firstActiveProduct.id,
                    'name': firstActiveProduct.name,
                    'description': firstActiveProduct.description,
                    'sku': firstActiveProduct.sku,
                    'value': firstActiveProduct['product-price']['gross-amount-value'].amount,
                    'price': firstActiveProduct['product-price']['gross-amount'].amount,
                    'contingent': firstActiveProduct['available-contingent'],
                    'minimum-quantity': firstActiveProduct['minimum-quantity'],
                    'templates': firstActiveProduct['media-templates'],
                }

                dispatch(CartStore.setCartTemplate(firstActiveProduct['media-templates'][0].id));

            }

            else {
                firstActiveProduct = product.products;
                let firstvalue = 0;
                let firstprice = 0;
                let subproducts = [];
                firstActiveProduct.products.forEach(subproduct => {

                    firstvalue += subproduct['minimum-quantity'] * subproduct['product-price']['gross-amount-value'].amount;
                    firstprice += subproduct['minimum-quantity'] * subproduct['product-price']['gross-amount'].amount;
                    subproducts.push(
                        {
                            'id': subproduct.id,
                            'sku': subproduct.sku,
                            'value': subproduct['product-price']['gross-amount-value'].amount,
                            'price': subproduct['product-price']['gross-amount'].amount,
                            'amount': subproduct['minimum-quantity'],
                            'templates': subproduct['media-templates'],
                            'activeTemplate': subproduct['media-templates'][0].id
                        }
                    )
                });

                data = {
                    'id': firstActiveProduct.id,
                    'name': firstActiveProduct.name,
                    'description': firstActiveProduct['description-short'],
                    'sku': firstActiveProduct.sku,
                    'value': firstvalue,
                    'price': firstprice,
                    'contingent': firstappointment ? (firstappointment.products.length < 2 ? firstappointment.products[0].contingent : null) : firstActiveProduct['available-contingent'],
                    'minimum-quantity': firstActiveProduct['minimum-quantity'],
                    'subproducts': subproducts
                }

                dispatch(CartStore.setCartSubproducts(subproducts));

            }

            if (producttype === 'choose' || producttype === 'all') {
                dispatch(ProductPageStore.setAmount(firstActiveProduct['minimum-quantity']));
            }

            dispatch(ProductPageStore.setProductData(data));
            dispatch(CartStore.setCartCategory(product.products['category-identifier']));
            dispatch(CartStore.setCartConsumer(product.products['consumer-number']));
            dispatch(CartStore.setCartProductId(product.products.id));
            dispatch(CartStore.setCartBundleSku(product.products.sku));
            dispatch(CartStore.setCartSku(firstActiveProduct.sku));
            dispatch(CartStore.setCartQty(firstActiveProduct['minimum-quantity']));
            dispatch(CartStore.setCartPersMsg(product.shop.shopDetails['greeting-text-default']));
            dispatch(CartStore.setCartPackaging(firstpackaging.id));
            dispatch(CartStore.setCartShipping(firstshipping.id));
        }
    }, [product, activeAmount, activeProductData, dispatch]);

    let view;

    if (activeProductData) {
        let showvalue = activeProductData.value !== activeProductData.price ? <div className="amountvalue">Gesamtwert: <span>{HelperService.formatPrice(activeProductData.value * activeAmount)}</span></div> : '';
        let showprice;

        if (couponActivePrice !== null) {
            showprice = couponActivePrice;
        }
        else {
            showprice = activeProductData.price * activeAmount;
        }

        const producttype = product.products['configuration-type'];

        const appointmentBased = product.products['appointment-based'];

        let formboxes = window.innerWidth < 1200
            ?
            <>
                <ProductOptionsPackaging type="boxes" mobile="yes" setCouponActivePrice={setCouponActivePrice} />
                <ProductDeliveryTime />
                <ProductOptionsShipping type="boxes" mobile="yes" setCouponActivePrice={setCouponActivePrice} />
            </>
            :
            <>
                <ProductFormOptionsPackaging setCouponActivePrice={setCouponActivePrice} />
                <ProductFormOptionsShipping setCouponActivePrice={setCouponActivePrice} />
            </>
        view =
            <>
                <div className="col-lg-5 col-md-7 col-sm-7 col-xs-12">
                    <h1>{activeProductData.name}</h1>
                    {showvalue}
                    <div className="price">Preis: <span>{HelperService.formatPrice(showprice)}</span></div>
                    <div className="priceinfo">inkl. MwSt. | zzgl. Versand</div>
                    <ProductDiscount />
                    <ProductCouponInfo />
                    <div className="pricetext">{activeProductData.description}</div>
                    <ProductContingent />


                    {producttype === 'choose' ?
                        <>
                            <ProductOptionsChoose setCouponActivePrice={setCouponActivePrice} type="boxes" />
                            <ProductTemplateButton productId={activeProductData.id} templateCount={activeProductData.templates.length} />
                        </>
                        :
                        (producttype === 'configure' ? <ProductOptionsConfigure /> :
                            (producttype === 'all' ? <ProductOptionsAll type="boxes" /> :
                                (producttype === 'appointment' ? <ProductOptionsAppointment type="boxes" /> :
                                    <ProductTemplateButton productId={activeProductData.id} templateCount={activeProductData.subproducts[0].templates.length} subnum="0" notype={true} />)))}
                    <ProductOptionsPackaging type="boxes" mobile="no" setCouponActivePrice={setCouponActivePrice} />
                    <ProductDeliveryTime />
                    <ProductOptionsShipping type="boxes" mobile="no" setCouponActivePrice={setCouponActivePrice} />
                    <ProductPersonalMessage />
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                    <form className="order" method="post" action="" autoComplete='off'>
                        <h2>Sofort bestellen</h2>
                        <ProductBotFields />
                        {producttype === 'choose' ? <ProductFormOptionsChoose setCouponActivePrice={setCouponActivePrice} /> : null}
                        {appointmentBased ? <ProductFormOptionsAppointment setCouponActivePrice={setCouponActivePrice} /> : ''}
                        {producttype === 'choose' || producttype === 'all' || producttype === null ? <ProductFormOptionsAmount setCouponActivePrice={setCouponActivePrice} /> : null}
                        {formboxes}
                        {activeProductData.price !== 0 && !useshoppingcart ? <ProductCouponCode setCouponActivePrice={setCouponActivePrice} /> : ''}
                        <ProductBotButton />
                        <ProductPaymentButtons couponActivePrice={couponActivePrice} />
                    </form>
                </div>
            </>
    }

    return (
        <>{view}</>
    );

}

export default ProductOptions;
