import { useForm, FormProvider } from 'react-hook-form';
import { useRef, useState } from 'react';
import ApiService from "../../services/api.service";
import HideOpenedSelect from "../helper/HideOpenedSelect";
import CheckoutSummaryBox from './CheckoutSummaryBox';

const CheckoutForm = ({activeStep, setActiveStep, setActiveCart, type, cart}) => {

    const { register, handleSubmit, formState: {errors}, setValue, clearErrors, setError } = useForm();

    const methods = useForm();

    const [error,setApiError] = useState(null);
    if (error) throw error;
    
    const emptyErrorMessage = 'Bitte fülle dieses Feld aus';
    const postalErrorMessage = 'Fehlerhafte Postleitzahl';
    const emailErrorMessage = 'Fehlerhafte E-Mail-Adresse';

    const [dhlError,setDhlError] = useState(false);
    const activeDhl = type !== 'email' && type !== 'intern' && !dhlError ? true : false;

    const [dhlBillingResponse, setDhlBillingResponse] = useState();
    const [dhlShippingResponse, setDhlShippingResponse] = useState();
    const [dhlBillingSelection, setDhlBillingSelection] = useState();
    const [dhlShippingSelection, setDhlShippingSelection] = useState();
    const [dhlBillingOptionsSelect, setDhlBillingOptionsSelect] = useState('hidden');
    const [dhlShippingOptionsSelect, setDhlShippingOptionsSelect] = useState('hidden');

    const billingRef = useRef(null);
    const shippingRef = useRef(null);
    HideOpenedSelect(billingRef, dhlBillingOptionsSelect, setDhlBillingOptionsSelect);
    HideOpenedSelect(shippingRef, dhlShippingOptionsSelect, setDhlShippingOptionsSelect);

    const [showShippingFields, setShowShippingFields] = useState(false);
    
    function showShippingFormFields(checked){
        checked ? setShowShippingFields(true) : setShowShippingFields(false)       
    }

    const dhlOnChangeField = (e, section) => {
        let combined = e.target.value;
        if (combined !== ''){              
            if (section === 'billing'){
                setDhlBillingSelection();
            }
            if (section === 'shipping'){
                setDhlShippingSelection();
            }
            const API_URL = global.config.express.url + '/dhldata';    
            const API_DATA = {
                'combined': combined
            }    
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof(response.apierror) !== 'undefined'){
                    setDhlError(true);
                    setValue('country', 'Deutschland');
                }
                else {
                    if (section === 'billing'){
                        setDhlBillingResponse(response);
                        setDhlBillingOptionsSelect('visible');
                    }
                    if (section === 'shipping'){
                        setDhlShippingResponse(response);
                        setDhlShippingOptionsSelect('visible');
                    }                   
                } 
            }).catch((error) => { setApiError(error) });
        }
        else {
            if (section === 'billing'){
                setDhlBillingResponse();
                setDhlBillingSelection();
                setDhlBillingOptionsSelect('hidden');
            }
            if (section === 'shipping'){
                setDhlShippingResponse();
                setDhlShippingSelection();
                setDhlShippingOptionsSelect('hidden');
            }
        }
    }

    const dhlChangeFieldValue = (e, data, section) => {
        if (section === 'billing'){
            setValue('billingaddress', e.target.innerText);
            setDhlBillingSelection({
                'street': data.street + ' ' + data.houseNumber,
                'postal': data.postalCode,
                'city': data.city
            })
            setDhlBillingOptionsSelect('hidden');
            clearErrors('billingaddress');
        }
        if (section === 'shipping'){
            setValue('shippingaddress', e.target.innerText);
            setDhlShippingSelection({
                'street': data.street + ' ' + data.houseNumber,
                'postal': data.postalCode,
                'city': data.city
            })
            setDhlShippingOptionsSelect('hidden');
            clearErrors('shippingaddress');
        }    
    }

    const jumpToSelection = (e) => {
        if (e.keyCode === 40){
            e.target.nextSibling.firstChild.focus();
        }
    }

    const jumpInsideSelection = (e) => {
        if (e.keyCode === 40){
            e.target.nextSibling.focus();
        }
        if (e.keyCode === 38){
            e.target.previousSibling.focus();
        }
    }

    let billingAdressSelection;
    if (dhlBillingResponse){
        billingAdressSelection = dhlBillingResponse.map((data) => (
            <button type="button" onClick={(e) => dhlChangeFieldValue(e, data, 'billing')} onMouseEnter={() =>  document.activeElement.blur()} onKeyDown={(e) => jumpInsideSelection(e)} key={data.uuid}>{data.street + ' ' + data.houseNumber + ', ' + data.postalCode + ' ' + data.city}</button>
        ));
    }

    let shippingAdressSelection;
    if (dhlShippingResponse){
        shippingAdressSelection = dhlShippingResponse.map((data) => (
            <button type="button" onClick={(e) => dhlChangeFieldValue(e, data, 'shipping')} onMouseEnter={() =>  document.activeElement.blur()} onKeyDown={(e) => jumpInsideSelection(e)} key={data.uuid}>{data.street + ' ' + data.houseNumber + ', ' + data.postalCode + ' ' + data.city}</button>
        ));
    }
    
    let shippingcheckbox;
    if (type !== 'email'){
        shippingcheckbox = 
            <div className="checkbox shippingcheckbox">
                <fieldset className="checkbox_container">
                    <span>
                        <input onChange={e => showShippingFormFields(e.target.checked)} type="checkbox" name="othershipping" className="checkbox" />
                        <label>Abweichende Lieferadresse angeben</label>
                    </span>
                </fieldset>
            </div>
    }

    let shippingfields;
    if (showShippingFields){
        shippingfields = 
        <>
            <h2>Lieferadresse</h2>
            <div className="third-left standard mandatory">
                <label>Vorname<span className="mandatory">*</span></label>
                <div className="formfieldholder">
                    <input 
                        type="text"                                    
                        {...register('shippingfirstname', {
                            required: emptyErrorMessage
                        })}
                    />
                    {errors.shippingfirstname && <div className="error">{errors.shippingfirstname.message}</div>}
                </div>
            </div>                 
            <div className="third-middle standard mandatory">
                <label>Nachname<span className="mandatory">*</span></label>
                <div className="formfieldholder">
                    <input 
                        type="text"                                     
                        {...register('shippinglastname', {
                            required: emptyErrorMessage
                        })}
                    />
                    {errors.shippinglastname && <div className="error">{errors.shippinglastname.message}</div>}                                            
                </div>
            </div>    
            {activeDhl 
            ? 
            <>
            <div className="half-left standard mandatory">
                <label>Anschrift<span className="mandatory">*</span></label>
                <div className="formfieldholder overflow input-holder" ref={shippingRef}>
                    <input 
                        type="text"                                     
                        {...register('shippingaddress', {
                            required: emptyErrorMessage      
                        })}
                        onChange={(e) => dhlOnChangeField(e, 'shipping')}
                        onKeyDown={(e) => jumpToSelection(e)}
                    />    
                    <div className={dhlShippingOptionsSelect + " select_options"}>                
                        {shippingAdressSelection}                                           
                    </div> 
                    {errors.shippingaddress && <div className="error">{errors.shippingaddress.message}</div>}                                   
                </div>
            </div> 
            </>
            :
            <>                             
            <div className="third-right standard mandatory">
                <label>Land<span className="mandatory">*</span></label>
                <div className="formfieldholder">
                    <input 
                        type="text"                                     
                        defaultValue={'Deutschland'}
                        disabled={true}
                        {...register('country')}
                    />                                        
                </div>
            </div>
            <div className="third-left standard mandatory">
                <label>Straße / Hausnummer<span className="mandatory">*</span></label>
                <div className="formfieldholder">
                    <input 
                        type="text"                                     
                        {...register('shippingstreet', {
                            required: emptyErrorMessage      
                        })}
                    />
                {errors.shippingstreet && <div className="error">{errors.shippingstreet.message}</div>}                      
                </div>
            </div>
            <div className="third-middle plz mandatory">
                <label>PLZ<span className="mandatory">*</span></label>
                <div className="formfieldholder">
                    <input 
                        type="text"                                     
                        maxLength={5}
                        {...register('shippingpostal', {
                            required: emptyErrorMessage,
                            pattern: {
                            value: /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/,
                            message: postalErrorMessage
                            }
                        })}
                    />
                {errors.shippingpostal && <div className="error">{errors.shippingpostal.message}</div>}                       
                </div>
            </div>
            <div className="third-right standard mandatory">
                <label>Ort<span className="mandatory">*</span></label>
                <div className="formfieldholder">
                    <input 
                        type="text"                                    
                        {...register('shippingcity', {
                            required: emptyErrorMessage      
                        })}
                    />
                {errors.shippingcity && <div className="error">{errors.shippingcity.message}</div>}                         
                </div>
            </div> 
            </>
            }
        </>
    }

    const onSubmit = (values) => {
        if (!activeDhl) {
            cart['billing-address'] = {
                'first-name': values.firstname,
                'last-name': values.lastname,
                'email': values.email,
                'country-code': 'DE',
                'street': values.street,
                'postal-code': values.postal,
                'city': values.city
            }
            if (values.shippingfirstname){
                cart['shipping-address'] = {
                    'first-name': values.shippingfirstname,
                    'last-name': values.shippinglastname,
                    'email': values.email,
                    'country-code': 'DE',
                    'street': values.shippingstreet,
                    'postal-code': values.shippingpostal,
                    'city': values.shippingcity
                }
            }
            else {
                cart['shipping-address'] = cart['billing-address'];
            }
            setActiveCart(cart);
            setActiveStep(3);
        }

        else {
            if (typeof(dhlBillingSelection) !== 'undefined'){           
                cart['billing-address'] = {
                    'first-name': values.firstname,
                    'last-name': values.lastname,
                    'email': values.email,
                    'country-code': 'DE',
                    'street': dhlBillingSelection.street,
                    'postal-code': dhlBillingSelection.postal,
                    'city': dhlBillingSelection.city
                }

                if (values.shippingfirstname){ 
                    if (typeof(dhlShippingSelection) !== 'undefined' ){
                        cart['shipping-address'] = {
                            'first-name': values.shippingfirstname,
                            'last-name': values.shippinglastname,
                            'email': values.email,
                            'country-code': 'DE',
                            'street': dhlShippingSelection.street,
                            'postal-code': dhlShippingSelection.postal,
                            'city': dhlShippingSelection.city
                        }
                    }
                    else {
                        setValue('shippingaddress', '');
                        setError('shippingaddress', { type: 'custom', message: 'Bitte wähle eine gültige Adresse' });
                        setDhlShippingSelection();
                        setDhlShippingOptionsSelect('hidden');
                        return false;
                    }
                }
                else {
                    cart['shipping-address'] = cart['billing-address'];
                }
            }
            else {
                setValue('billingaddress', '');
                setError('billingaddress', { type: 'custom', message: 'Bitte wähle eine gültige Adresse' });
                setDhlBillingSelection();
                setDhlBillingOptionsSelect('hidden');
                return false;
            }                
        }       

        if (!errors.billingaddress && !errors.shippingaddress){
            setActiveCart(cart);
            setActiveStep(3);
        }
       
    }

    if (activeStep === 2){
        return (
            <div className="container">            
                <div className="row">
                    <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} className="form-add">
                        <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12 smallxl-8 prightbig">
                            <h2>Rechnungsadresse</h2>
                            <div className="half-left standard mandatory">
                                <label>Vorname<span className="mandatory">*</span></label>
                                <div className="formfieldholder">
                                    <input 
                                        type="text"                                    
                                        {...register('firstname', {
                                            required: emptyErrorMessage
                                        })}
                                    />
                                    {errors.firstname && <div className="error">{errors.firstname.message}</div>}
                                </div>
                            </div>                 
                            <div className="half-right standard mandatory">
                                <label>Nachname<span className="mandatory">*</span></label>
                                <div className="formfieldholder">
                                    <input 
                                        type="text"                                     
                                        {...register('lastname', {
                                            required: emptyErrorMessage
                                        })}
                                    />
                                    {errors.lastname && <div className="error">{errors.lastname.message}</div>}                                            
                                </div>
                            </div>
                            <div className="half-left standard mandatory">
                                <label>E-Mail-Adresse<span className="mandatory">*</span></label>
                                <div className="formfieldholder">
                                    <input 
                                        type="text"                     
                                        placeholder="Benötigen wir nur für die Bestellbestätigung"               
                                        {...register('email', {
                                            required: emptyErrorMessage,
                                            pattern: {
                                                // eslint-disable-next-line
                                                value: /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
                                                message: emailErrorMessage
                                            }
                                        })}
                                    />
                                    {errors.email && <div className="error">{errors.email.message}</div>}
                                </div>
                            </div>  
                            {activeDhl 
                            ? 
                            <>
                            <div className="half-right standard mandatory">
                                <label>Anschrift<span className="mandatory">*</span></label>
                                <div className="formfieldholder overflow input-holder" ref={billingRef}>
                                    <input 
                                        type="text"                                     
                                        {...register('billingaddress', {
                                            required: emptyErrorMessage      
                                        })}
                                        onChange={(e) => dhlOnChangeField(e, 'billing')}
                                        onKeyDown={(e) => jumpToSelection(e)}
                                    />    
                                    <div className={dhlBillingOptionsSelect + " select_options"}>                
                                        {billingAdressSelection}                                           
                                    </div> 
                                    {errors.billingaddress && <div className="error">{errors.billingaddress.message}</div>}                                   
                                </div>
                            </div> 
                            </>
                            :
                            <>
                            <div className="half-right standard mandatory">
                                <label>Land<span className="mandatory">*</span></label>
                                <div className="formfieldholder">
                                    <input 
                                        type="text"                                     
                                        defaultValue={'Deutschland'}
                                        disabled={true}
                                        {...register('country')}
                                    />                                        
                                </div>
                            </div>
                            <div className="third-left standard mandatory">
                                <label>Straße / Hausnummer<span className="mandatory">*</span></label>
                                <div className="formfieldholder">
                                    <input 
                                        type="text"                                     
                                        {...register('street', {
                                            required: emptyErrorMessage      
                                        })}
                                    />
                                {errors.street && <div className="error">{errors.street.message}</div>}                      
                                </div>
                            </div>
                            <div className="third-middle plz mandatory">
                                <label>PLZ<span className="mandatory">*</span></label>
                                <div className="formfieldholder">
                                    <input 
                                        type="text"                                     
                                        maxLength={5}
                                        {...register('postal', {
                                            required: emptyErrorMessage,
                                            pattern: {
                                            value: /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/,
                                            message: postalErrorMessage
                                            }
                                        })}
                                    />
                                {errors.postal && <div className="error">{errors.postal.message}</div>}                       
                                </div>
                            </div>
                            <div className="third-right standard mandatory">
                                <label>Ort<span className="mandatory">*</span></label>
                                <div className="formfieldholder">
                                    <input 
                                        type="text"                                    
                                        {...register('city', {
                                            required: emptyErrorMessage      
                                        })}
                                    />
                                {errors.city && <div className="error">{errors.city.message}</div>}                         
                                </div>
                            </div>  
                            </>
                            }               
                              
                            {shippingcheckbox}    
                            {shippingfields}                    
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 smallxl-4">
                            <div className="shortsummary">
                                <h3>Bestellung</h3>
                                <CheckoutSummaryBox cart={cart} />
                            </div>
                            <div className="submit_container">
                                <div className="formfieldholder">
                                    <button type="submit" className="submit full">weiter</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    </FormProvider>
                </div>
            </div>
        );
    }

}

export default CheckoutForm;