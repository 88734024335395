const initialState = {
  speech: 'informal',
  useshoppingcart: false
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {        
        case 'SET_SPEECH': {
            return {
                ...state,
                speech: action.speech,
            };
        }
        case 'SET_USESHOPPINGCART': {
            return {
                ...state,
                useshoppingcart: action.useshoppingcart,
            };
        }
        case 'SET_PREDEFINEDCODE': {
            return {
                ...state,
                predefinedcode: action.predefinedcode,
            };
        }
        case 'SET_APIERROR': {
            return {
            ...state,
            apierror: action.apierror,
            };
        }
        default: {
            return state;
        }
    }
};

export default Reducer;
