import { useEffect, useState } from 'react';
import ApiService from "../services/api.service";
import OrderSuccessContent from './ordersuccesspage/OrderSuccessContent';
import { useDispatch } from 'react-redux';
import * as ShoppingCartStore from '../store/shoppingcart/actions';

const OrderViewPage = () => {
   
    const dispatch = useDispatch();
    dispatch(ShoppingCartStore.resetShoppingCart());

    const [successCart, setSuccessCart] = useState();
    const [successDownload, setSuccessDownload] = useState();
    const [productCount, setProductCount] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search)
    const identifier = queryParameters.get("transitIdentifier")     
    
    const [error,setError] = useState(null);
    if (error) throw error;

    useEffect(() => {        
        if (identifier){
            const API_URL = global.config.express.url + '/cart/details';
            const API_DATA = {
                'transit-identifier': identifier,
                'shop-url': global.config.apidata.shopurl
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof(response.apierror) !== 'undefined'){
                    setError(response.apierror);
                }
                else {   
                    setSuccessCart(response.cart);
                    setSuccessDownload(response.download);
                    setProductCount(response.productcount);

                    var _paq = window._paq;
                    if (typeof _paq !== "undefined"){             
                        let productarray = [];
                        response.cart['order-product-bundle-groups'].forEach(group => {
                            group['order-product-bundles'].forEach(bundle => {
                                bundle['order-products'].forEach(product => {
                                    if (product['identification-number'] !== ''){
                                        const checkSku = obj => obj.sku === product['sku'];
                                        if (productarray.some(checkSku)){
                                            let idx = productarray.findIndex((obj => obj.sku === product['sku']));
                                            productarray[idx].count++;
                                        }
                                        else {
                                            productarray.push({
                                                name: product.name,
                                                sku: product.sku,
                                                price: product['price-total']['gross-amount-value']['amount'],
                                                count: 1
                                            })
                                        }
                                    }
                                });
                            });
                        });
                        productarray.forEach(product => {
                            _paq.push(["addEcommerceItem",product.sku,product.name,"",product.price,product.count]);
                        });    
                        _paq.push(["trackEcommerceOrder",response.cart['order-number'],response.cart['cart-price-total']['gross-amount-value'].amount]);                                                  
                    }  
                    
                }
            }).catch((error) => { setError(error) });
        }
        else {
            setError('NO_IDENTIFIER')
        }
    }, [identifier]);

    return (
        <div className="container nopadding">            
            <OrderSuccessContent cart={successCart} successDownload={successDownload} productCount={productCount} />
        </div>
    );
}

export default OrderViewPage;