export const resetData = data => ({
  type: 'RESET',
  data
});

export const setCartCategory = category => ({
  type: 'SET_CART_CATEGORY',
  category
});

export const setCartConsumer = consumer => ({
  type: 'SET_CART_CONSUMER',
  consumer
});

export const setCartProductId = productid => ({
  type: 'SET_CART_PRODUCTID',
  productid
});

export const setCartBundleSku = bundlesku => ({
  type: 'SET_CART_BUNDLESKU',
  bundlesku
});

export const setCartSku = sku => ({
  type: 'SET_CART_SKU',
  sku
});

export const setCartQty = qty => ({
  type: 'SET_CART_QTY',
  qty
});

export const setCartPersMsg = msg => ({
  type: 'SET_CART_PERSMSG',
  msg
});

export const setCartPackaging = packaging => ({
  type: 'SET_CART_PACKAGING',
  packaging
});

export const setCartShipping = shipping => ({
  type: 'SET_CART_SHIPPING',
  shipping
});

export const setCartCouponCode = couponcode => ({
  type: 'SET_CART_COUPONCODE',
  couponcode
});

export const setCartSubproducts = subproducts => ({
  type: 'SET_CART_SUBPRODUCTS',
  subproducts
});

export const setCartAppointment = appointment => ({
  type: 'SET_CART_APPOINTMENT',
  appointment
});

export const setCartTemplate = template => ({
  type: 'SET_CART_TEMPLATE',
  template
});