import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import ApiService from "../../services/api.service";

const CheckoutPayments = ({ activePayment, setActivePayment, cart }) => {

    const productproviders = useSelector(state => state.productpage.payments);
    const activeProductData = useSelector(state => state.productpage.productdata);
    const [paymentOptions, setPaymentOptions] = useState([]);
    const wasCalled = useRef(false);
    const [providers, setProviders] = useState(productproviders);
    const [shoppingCartProviders, setShoppingCartProviders] = useState(false);

    const useshoppingcart = useSelector(state => state.global.useshoppingcart);

    useEffect(() => {
        if (useshoppingcart && shoppingCartProviders) {
            const API_URL = global.config.express.url + '/getpayments';
            const API_DATA = {
                'shop-url': global.config.apidata.shopurl,
                'backendshop': global.config.backendshop
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                setProviders(response.paymentProviders);
                setShoppingCartProviders(true)
            });
        }
    }, [providers, useshoppingcart, shoppingCartProviders]);


    let coupon = useSelector(state => state.productpage.coupon);

    useEffect(() => {

        let paymentOptions = [];
        let finalprice;
        let finalcoupon;
        if (useshoppingcart) {
            finalprice = cart['cart-price-total']['gross-amount-value']['amount'];
            finalcoupon = cart['coupon-balance']
                ? {
                    price: cart['coupon-balance']['new-gross-amount']['amount'],
                    shippingpackagingprice: cart['cart-method-price']['gross-amount-value']['amount']
                }
                : null
        }
        else {
            finalprice = activeProductData.price;
            finalcoupon = coupon;
        }


        if (finalprice === 0 || (finalcoupon && finalcoupon.price === 0 && finalcoupon.shippingpackagingprice === 0)) {

            if (finalprice === 0) {
                paymentOptions.push(
                    <p key="couponcode">
                        Nicht erforderlich
                    </p>
                );
            }
            else {
                paymentOptions.push(
                    <p key="couponcode">
                        Mit eingelöstem Couponcode
                    </p>
                );
            }

            if (!wasCalled.current) {
                wasCalled.current = true;
                setActivePayment({
                    'payment-provider': null,
                    'payment-method-category': null,
                    'payment-method-value': null,
                    'payment-kind': 'OneTime'
                })
            };

        }
        else {
            if (providers) {
                let key = 0;
                providers.forEach((provider) => {
                    provider['payment-call-tupel'].forEach((item) => {
                        if (provider.active && item['payment-kind'] === 'OneTime') {
                            if (key === 0 && activePayment.length === 0) {
                                setActivePayment(item);
                            }
                            let paymenticon;
                            if (item['payment-provider-icon'] && item['payment-provider-icon']['relative-path']) {
                                paymenticon = <img className="paymenticon" src={global.config.apidata.url + '/' + item['payment-provider-icon']['relative-path']} alt={item['payment-provider-icon']['alt']} />
                            }
                            if (item['payment-method-value'] === 'apple_pay' && (typeof (window.ApplePaySession) === 'undefined' || !window.ApplePaySession.canMakePaymentsWithActiveCard())) {
                                return
                            }
                            else {
                                paymentOptions.push(
                                    <button type="button" onClick={() => setActivePayment(item)} className={"payment" + (item['payment-name'] === activePayment['payment-name'] ? ' active' : '')} key={key}>
                                        {paymenticon}
                                        {item['payment-name']}
                                    </button>
                                );
                                key++;
                            }
                        }
                    });
                });
            }
        }
        setPaymentOptions(paymentOptions);
    }, [providers, setPaymentOptions, activePayment, setActivePayment, coupon, activeProductData, cart, useshoppingcart]);


    return (
        <div className="payments">
            {paymentOptions}
        </div>
    );

}

export default CheckoutPayments;